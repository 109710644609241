<template>
  <div>
    <div class="login">
      <div class="tit">
        <div class="l">绑定沪惠学</div>
      </div>
      <div class="tel">
        <input
          class="info-input1"
          v-model="from.phone"
          placeholder="请输入手机号码"
        />
      </div>

      <button class="btn" @click="btn">登录</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      from: {
        invited: "",
        phone: "",
        openid: "",
        first_login_path: "",
        picture: "",
      },
    };
  },
  computed: {
    ...mapState({
      wx: (state) => state.wx,
      invitation: (state) => state.invitation,
      invitationinif: (state) => state.invitationinif,
      login_path: (state) => state.login_path,
    }),
  },
  methods: {
    ...mapActions(["getbinding", "getinformation_up"]),
    btn() {
      if (this.from.phone == "") {
        this.$toast.fail("请输入手机号");
        return;
      }
      if (this.invitation) {
        this.from.invited = this.invitation;
      } else {
        this.from.invited = this.invitationinif.invitation;
      }
      this.from.first_login_path = this.login_path;
      this.from.openid = this.wx.openid;
      this.from.picture = this.wx.headimgurl;
      this.getbinding(this.from).then((res) => {
        if (res.SuccessCode == 200) {
          this.$store.commit("SET_USERINFO", res.ResponseBody);
          if (this.login_path == "") {
            this.$router.push("/user");
          } else {
            let is_url = this.login_path.replace(
              /https:\/\/huhuixue.zpt.udao.sh.cn/g,
              ""
            );
            this.$store.commit("SET_FALG", 2);
            this.$router.push(is_url);
          }
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
/* pages/pologn/index.wxss */
.login {
  margin-top: 0.7rem;
  box-sizing: border-box;
}

.tit {
  display: flex;
  padding: 0.62rem 0.7rem 0 0.8rem;
  margin-bottom: 0.5rem;
}

.tit .m {
  padding-top: 0.2rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #bcbcbc;
  line-height: 0.4rem;
}

.tit .l {
  font-size: 0.5rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 0.8rem;
  margin-right: 1.7rem;
}

.tit .r {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(159, 160, 160, 1);
  line-height: 0.44rem;
  margin-top: 0.26rem;
}

.tel {
  width: 6rem;
  height: 1.18rem;
  display: flex;
  margin-left: 0.8rem;
  border-bottom: 0.01rem solid #eee;
}
.info-input1 {
  width: 3rem;
  font-size: 0.34rem;
  border: 0rem none;
}
.info-input1::placeholder {
  color: #ccc;
}
.info-input::placeholder {
  color: #ccc;
}
.info-input {
  width: 3rem;
  font-size: 0.34rem;
  border: 0rem none;
}

.pas {
  width: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pas button {
  line-height: 0.4rem;
  text-align: center;
  font-size: 0.28rem;
  // margin-top: 0.3rem;
  height: 0.4rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000;
  background-color: #fff;
  border: 0px none;
}

.tel img {
  width: 0.28rem;
  height: 0.4rem;
  margin-right: 0.22rem;
  margin-top: 0.44rem;
}

.tel input {
  margin-top: 0.44rem;
}

.tel text {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 800;
  color: rgba(255, 97, 0, 1);
  line-height: 0.4rem;
  margin-top: 0.6rem;
  margin-left: 0.8rem;
}

.pas {
  height: 1.18rem;
  display: flex;
  margin-left: 0.8rem;
  margin-bottom: 0.84rem;
}

.pas img {
  width: 0.34rem;
  margin-right: 0.2rem;
  height: 42px;
  margin-top: 0.6rem;
}

.btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  width: 5.9rem !important;
  height: 0.8rem !important;
  background: rgba(255, 90, 71, 1);
  border-radius: 0.4rem;
  color: #fff;
  font-size: 0.32rem;
  margin: 0 auto;
  line-height: 0.8rem !important;
  padding: 0rem !important;
  border: 0;
}

.for {
  width: 100%;
  height: 0.5rem;
  display: flex;
}

.forget {
  /* width: 80rpx; */
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 800;
  color: rgba(0, 145, 255, 1);
  line-height: 0.5rem;
  margin-left: 0.8rem;
}

.re {
  /* width: 150rpx; */
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 800;
  color: rgba(0, 145, 255, 1);
  line-height: 0.5rem;
  margin-left: 2rem;
}

.wx {
  width: 0.6rem;
  height: 0.6rem;
  margin-top: 0.2rem;
  margin-left: 3.46rem;
}

.van-divider {
  margin-top: 2.6rem !important;
}
</style>